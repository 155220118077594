// Fade Right

.fadeRight {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}

// Fade Left

.fadeLeft-1 {
  animation: fadeInLeft 0.3s ease-in-out;
}
.fadeLeft-2 {
  animation: fadeInLeft 1.4s ease-in-out;
}
.fadeLeft-3 {
  animation: fadeInLeft 3s ease-in-out;
}
@media (min-width: 900px) {
  .fadeleft-x {
    animation: fadeInLeftX 1.5s ease-in-out;
  }

  .nav-logo-anim {
    animation: fadeInLeftX2 1.5s ease-in-out;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeftX {
  from {
    transform: translateX(-200%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeftX2 {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Fade In

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes videoFade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.6;
    background-blend-mode: multiply;
    filter: brightness(20%);
  }
}

.site-heading {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.site-subHeading {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.videoFade {
  opacity: 1;
  animation: videoFade 1s;
  animation-delay: 3.3s;
  animation-fill-mode: forwards;
}

.header-fade-1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3.3s;
  animation-fill-mode: forwards;
}

.header-fade-2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
}
.header-fade-3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3.7s;
  animation-fill-mode: forwards;
}

.nav-fade-1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

.nav-fade-2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.nav-fade-3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.nav-fade-4 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

.nav-fade-5 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.nav-fade-6 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.nav-fade-7 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

.nav-fade-8 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}
.nav-fade-9 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
}
.nav-fade-10 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.object {
  width: 40px;
  -webkit-animation: shimmy 52s infinite;
  animation: shimmy 5s infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes shimmy {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(200px, 250px);
    transform: translate(200px, 250px);
  }
}

@keyframes shimmy {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 250px);
    transform: translate(0, 250px);
  }
}

.ta-word {
  overflow: hidden;
  display: inline-flex;
  margin-right: 0.15em;
  position: relative;
}

@keyframes ta-color {
  from {
    color: currentColor;
  }
  to {
    @apply text-text;
  }
}

.ta-color {
  animation: ta-color 1s ease-out forwards;
  animation-delay: 2s;
}

.ipsType_right {
  text-align: right;
}
.ipsType_center {
  text-align: center;
}
.cInnerContent {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}
.ipsSpacer_bottom_double {
  margin-bottom: 30px;
}
.ipsGrid {
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.ipsGrid::before,
.ipsGrid::after {
  display: table;
  content: "";
  line-height: 0;
}
.ipsGrid > [class*="ipsGrid_span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}
.ipsGrid > .ipsGrid_span5 {
  width: 40.42553191489362%;
}
.ipsGrid > .ipsGrid_span7 {
  width: 57.44680851063829%;
}
.ipsGrid > [class*="ipsGrid_span"] {
  float: left;
  margin-left: 2%;
}
.ipsGrid > [class*="ipsGrid_span"]:first-child {
  margin-left: 0;
}
.feature {
  display: flex;
  align-items: center;
}
.card {
  margin-bottom: 30px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  /*   transition: 0.3s; */
}
.featured-image-container .card {
  padding: 10px;
  height: 0;
  padding-bottom: calc(100% - 10px);
}
h2.heading_large {
  font-size: 1.8em;
}
img {
  max-width: 100%;
}

.header-section {
  margin: 200px auto;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

// SVG Anims

.svg-anim:hover {
  @-webkit-keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 210.4215087890625px;
      stroke-dasharray: 210.4215087890625px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 210.4215087890625px;
    }
  }
  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 210.4215087890625px;
      stroke-dasharray: 210.4215087890625px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 210.4215087890625px;
    }
  }
  .svg-elem-1 {
    -webkit-animation: animate-svg-stroke-1 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
    animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
      both;
  }
  @-webkit-keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 123.4854507446289px;
      stroke-dasharray: 123.4854507446289px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 123.4854507446289px;
    }
  }
  @keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 123.4854507446289px;
      stroke-dasharray: 123.4854507446289px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 123.4854507446289px;
    }
  }
  .svg-elem-2 {
    -webkit-animation: animate-svg-stroke-2 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both;
    animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s
      both;
  }
  @-webkit-keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 95.42132568359375px;
      stroke-dasharray: 95.42132568359375px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 95.42132568359375px;
    }
  }
  @keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 95.42132568359375px;
      stroke-dasharray: 95.42132568359375px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 95.42132568359375px;
    }
  }
  .svg-elem-3 {
    -webkit-animation: animate-svg-stroke-3 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both;
    animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s
      both;
  }
  @-webkit-keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 96.3766860961914px;
      stroke-dasharray: 96.3766860961914px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 96.3766860961914px;
    }
  }
  @keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 96.3766860961914px;
      stroke-dasharray: 96.3766860961914px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 96.3766860961914px;
    }
  }
  .svg-elem-4 {
    -webkit-animation: animate-svg-stroke-4 0.5s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both;
    animation: animate-svg-stroke-4 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.36s both;
  }
  @-webkit-keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 19.27199935913086px;
      stroke-dasharray: 19.27199935913086px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 19.27199935913086px;
    }
  }
  @keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 19.27199935913086px;
      stroke-dasharray: 19.27199935913086px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 19.27199935913086px;
    }
  }
  .svg-elem-5 {
    -webkit-animation: animate-svg-stroke-5 0.5s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both;
    animation: animate-svg-stroke-5 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
      0.48s both;
  }
}
