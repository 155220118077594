html,
body {
  scroll-behavior: smooth;
  font-family: "Gordita", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

a,
button {
  span {
    text-decoration: none !important;
    font-size: responsive 14px 16px;
    line-height: responsive 14px 16px;
    letter-spacing: 1px;
    font-family: work-sans, sans-serif !important;
  }
  text-decoration: none !important;
  font-size: responsive 14px 16px;
  line-height: responsive 14px 16px;
  letter-spacing: 1px;
  font-family: work-sans, sans-serif !important;
}

// main {
//   transform: translateZ(0);
// }

li {
  font-family: space-grotesk, sans-serif;
}

.wrapper {
  position: relative !important;
  padding-left: 120px !important;
  padding-right: 120px !important;
  max-width: 1650px !important;
  margin: auto !important;
  @media (max-width: 1200px) {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  @media (max-width: 900px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @media (max-width: 768px) {
    font-size: 30px !important;
    line-height: 32px !important;
  }
}
.home-title {
  @media (max-width: 768px) {
    font-size: 18px !important;
    line-height: 32px !important;
  }
}
h1,
.h1 {
  font-size: responsive 24px 50px;
  line-height: responsive 28px 53px;
  font-family: space-grotesk, sans-serif;
}

h2,
.h2 {
  font-family: space-grotesk, sans-serif;
  font-size: responsive 30px 90px;
  line-height: responsive 32px 95px;
}

h3,
.h3 {
  font-family: space-grotesk, sans-serif;
  font-size: responsive 17px 20px;
  letter-spacing: -5px;
  @include letter-spacing(-0.3);
}

h4,
.h4 {
  font-size: 15px;
  @include letter-spacing(-0.3);
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  font-size: responsive 15px 20px;
  line-height: responsive 19px 28px;
  letter-spacing: 1px;
  font-family: work-sans, sans-serif;
}

label,
input {
  font-size: responsive 14px 15px;
  line-height: responsive 14px 15px;
  letter-spacing: 1px;
  font-family: work-sans, sans-serif;
}

span {
  font-family: work-sans;
}

.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}

.main-footer a {
  white-space: nowrap;
}

.province-select {
  overflow: hidden;
  .flickity-viewport {
    overflow: visible;
  }
}

.coloured-heading {
  strong {
    color: #73ffb5 !important;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .nav-links {
    padding-top: 0;
  }
  .b-demo {
    background-color: #73ffb5 !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  background-color: rgba(0, 0, 0, 0.475) !important;
  padding-left: 30px !important;
  padding-right: 0px !important;
  .logo {
    height: 35px !important;
    width: auto !important;
  }
  @media (max-width: 640px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .book-btn {
    height: 30px !important;
    width: 100px !important;
  }
  @media (max-width: 1024px) {
    background-color: rgba(0, 0, 0, 0.824) !important;
    .b-demo {
      background-color: #73ffb47f !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    padding-right: 0 !important;
    padding-left: 15px !important;

    .logo {
      height: 35px !important;
    }
  }
}

.headroom--top {
  @media (max-width: 640px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .nav-links {
    padding-top: 10px;
  }
  .b-demo {
    background-color: #73ffb5 !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  // height: 125px !important;
  .logo {
    height: 50px !important;
    width: 100% !important;
  }
  @media (max-width: 1023px) {
    .b-demo {
      margin-top: 20px;
      background-color: #73ffb5 !important;
      padding-left: 30px !important;
      padding-right: 0px !important;
    }
    padding-right: 0px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 45px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
  @media (max-width: 800px) {
    height: 60px !important;
    padding-right: 0px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 30px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
  @media (max-width: 300px) {
    height: 100px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 30px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
}
.toggle {
  div {
    background-color: white;
    height: 2px !important;
  }
}
.toggle {
  .line-2 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}

.blur-reveal {
  max-width: 700px;
  position: relative;
  overflow: hidden;
  font-size: 0;
}

.blur-reveal img {
  width: 100%;
}

.blur-reveal img:first-of-type {
  filter: blur(5px) !important;
}

.blur-reveal img:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*   mix-blend-mode: difference; */
}
.parent {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.div1 {
  grid-area: 1 / 3 / 7 / 7;
}
.div2 {
  grid-area: 4 / 7 / 11 / 11;
}
.div3 {
  grid-area: 7 / 1 / 11 / 7;
}
.category {
  position: -webkit-sticky;
  top: 0;
  position: sticky;
}

.pink-cat {
  top: 100px !important;
}

.yellow-cat {
  top: 100px !important;
}

.button:active {
  opacity: 1;
  color: #73ffb5 !important;
}

.button:disabled {
  color: white !important;
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

/* cell number */
.carousel-cell:before {
  display: none;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.blur-reveal {
  position: relative;
  overflow: hidden;
  font-size: 0;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel-nav .carousel-cell {
  height: 80px;
  width: 100px;
}

.carousel-nav .carousel-cell:before {
  font-size: 50px;
  line-height: 80px;
}

.carousel-nav .carousel-cell.is-nav-selected {
  background: #ed2;
}

.blur-reveal img {
  width: 100%;
}

.blur-reveal img:first-of-type {
  filter: blur(5px) !important;
}

.blur-reveal img:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*   mix-blend-mode: difference; */
}

.blue-cat {
  div {
    img {
      margin-top: -450px;
    }
  }
  top: 600px !important;
}

.no-mouse-today {
  cursor: none !important;
}

.blurred-image {
  cursor: none !important;
}

/* Global Button Styles */
a.animated-button:link,
a.animated-button:visited {
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  border-radius: 0;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
a.animated-button:link:after,
a.animated-button:visited:after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}
a.animated-button:link:hover,
a.animated-button:visited:hover {
  color: #000;
  text-shadow: none;
}
a.animated-button:link:hover:after,
a.animated-button:visited:hover:after {
  height: 450%;
}
a.animated-button:link,
a.animated-button:visited {
  position: relative;
  display: block;
  padding: 1px 1px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

/* Thar Buttons */

a.animated-button.thar-three {
  color: #000;
  cursor: pointer;
  display: block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover {
  color: #73ffb5 !important;
  background-color: transparent;
}
a.animated-button.thar-three:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-three:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: 300;
  content: "";
  color: #73ffb5 !important;
  background: #000;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

:root {
  --dark-color: hsl(var(--hue), 100%, 9%);
  --light-color: hsl(var(--hue), 95%, 98%);
  --base: hsl(var(--hue), 95%, 100%);
  --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
  --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);
}

.orb-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.ball {
  position: absolute;
  border-radius: 100%;
  opacity: 0.7;
  z-index: 0;
}

.bg-white {
  background-color: white !important;
}

.bg-black {
  background-color: black !important;
}

progress {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3px;
  border: none;
  background: transparent;
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  background: linear-gradient(to left, #73ffb5);
  background-attachment: fixed;
}
progress::-moz-progress-bar {
  background: linear-gradient(to left, #73ffb5);
  background-attachment: fixed;
}

nav {
  z-index: 999999999 !important;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999999 !important;

  &__element {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(100%);
    transform-origin: center right;
    background: #000;

    &:first-of-type {
      background: #73ffb5;
    }
  }
}

#nc-main {
  background: black;
}

#stars {
  @media (max-width: 1000px) {
    height: 400px;
    width: 400px;
    box-shadow: 300px 400px #fd3a77;
  }
  width: 700px;
  border-radius: 100%;
  filter: blur(15px);
  height: 700px;
  background: transparent;
  box-shadow: 1000px 800px #fd3a77;
  -webkit-animation: animStar 50s linear infinite;
  -moz-animation: animStar 50s linear infinite;
  -ms-animation: animStar 50s linear infinite;
  animation: animStar 50s linear infinite;
}

#stars2 {
  @media (max-width: 1000px) {
    height: 400px;
    width: 400px;
    box-shadow: -100px 550px #d3ff1e;
  }
  width: 700px;
  border-radius: 100%;
  filter: blur(15px);
  height: 700px;
  background: transparent;
  box-shadow: -300px 1100px #d3ff1e;
  -webkit-animation: animStar 50s linear infinite;
  -moz-animation: animStar 50s linear infinite;
  -ms-animation: animStar 50s linear infinite;
  animation: animStar 50s linear infinite;
}

@-moz-keyframes animStar {
  from {
    -moz-transform: translateY(0px);
  }
  to {
    -moz-transform: translateY(-2000px);
  }
}
@-ms-keyframes animStar {
  from {
    -ms-transform: translateY(0px);
  }
  to {
    -ms-transform: translateY(-2000px);
  }
}
@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.circle1:hover {
  fill: #fd3a77 !important;
  transition: ease-in-out 1s;
}

.circle2:hover {
  fill: #73ffb5 !important;
  transition: ease-in-out 1s;
}

.circle3:hover {
  fill: #d3ff1e !important;
  transition: ease-in-out 1s;
}

.circle1,
.circle2,
.circle3 {
  transition: ease-in-out 2s;
  cursor: none !important;
}

.keyfeature1:hover {
  .circle {
    fill: #fd3a77 !important;
    transition: ease-in-out 1s;
  }
}

#parent-div {
  cursor: none !important;
}
input,
textarea {
  outline: none !important;
}
.border-yellow {
  border-color: #d3ff1e;
}
.border-pink {
  border-color: #fd3a77;
}
.border-blue {
  border-color: #7ffbff;
}
.border-green {
  border-color: #73ffb5;
}
.text-yellow {
  color: #d3ff1e;
}
.text-pink {
  color: #fd3a77;
}
.text-blue {
  color: #7ffbff;
}
.bg-yellow {
  background: #d3ff1e;
}
.bg-pink {
  background: #fd3a77;
}
.bg-blue {
  background: #7ffbff;
}
.service-overview {
  p {
    font-size: 16px;
  }
  a {
    font-size: 14px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}
.nav-items {
  font-size: 40px;
  @media (max-width: 500px) {
    font-size: 27px;
  }
}

.button-fade-in-1 {
  p {
    padding-left: 50px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 25px;
      width: 40px;
      height: 2px;
      margin-top: 5px;
      background: white;
    }
  }
  p.active {
    color: #73ffb5;
    &::before {
      background: #73ffb5;
    }
  }
}

.bg-black-grad {
  background: rgb(0, 0, 0) !important;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
}

.rotate-fixed {
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: left top;
  -moz-transform: rotate(90deg);
  -moz-transform-origin: left top;
  -ms-transform: rotate(90deg);
  -ms-transform-origin: left top;
  -o-transform: rotate(90deg);
  -o-transform-origin: left top;
  transform: rotate(90deg);
  transform-origin: left top;

  position: fixed;
  top: 60vh;
  @media (max-width: 1024px) {
    top: 50vh;
  }
  @media (max-width: 640px) {
    top: 30vh;
  }
  left: 100%;
  white-space: nowrap;
}

.intro-text {
  @media (max-width: 640px) {
    p {
      display: none;
    }
  }
  p:nth-child(1) {
    display: block !important;
  }
}
